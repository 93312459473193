import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
import { Link } from '~/src/components/Link';
import { CDNComparison } from "~/src/components/CDNComparison";
import schema from '~/src/pages/guides/ld-json-schemas/map-operations.json';
export const frontmatter = {
  title: 'How to move your image processing operations to Uploadcare from Filestack and Cloudinary',
  description: 'Uploadcare provides an extensive image transformation toolkit. Use this article to map your previous CDN image operations to Uploadcare.',
  header: 'Map image processing operations between Uploadcare, Filestack and Cloudinary',
  schema
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Link to="/guides/how-to-transfer-your-files/" target="_blank" mdxType="Link">Transferring your files from other services using our Migro tool</Link> is a necessary first step. Sometimes this process is not sufficient — for instance, when you need to transfer images.
    <p>{`Modern CDNs often provide rich URL-based APIs for image transformations. The complexity of these solutions makes text-based guides a poor means of their description.`}</p>
    <p>{`We've come up with a solution to this issue. Below is a little application that can help you discover how to translate the API methods of Filestack, and Cloudinary.`}</p>
    <p>{`We chose and illustrated several methods that our clients use frequently. We’re working on enriching this app’s functionality, so if it’s useful to you and you’d like to see more operations in it, please `}<Link to="mailto:help@uploadcare.com" target="_blank" mdxType="Link">{`contact us`}</Link>{`.`}</p>
    <p>{`Find all Uploadcare image operations in our `}<Link to="https://uploadcare.com/docs/transformations/image/" target="_blank" mdxType="Link">{`Documentation`}</Link>{`.`}</p>
    <h2 {...{
      "id": "map-using-this-app"
    }}><a parentName="h2" {...{
        "href": "#map-using-this-app"
      }}>{`Map using this app`}</a></h2>
    <CDNComparison mdxType="CDNComparison" />
    <h2 {...{
      "id": "tables-of-operations"
    }}><a parentName="h2" {...{
        "href": "#tables-of-operations"
      }}>{`Tables of operations`}</a></h2>
    <h3 {...{
      "id": "cloudinary"
    }}><a parentName="h3" {...{
        "href": "#cloudinary"
      }}>{`Cloudinary`}</a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Operation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cloudinary`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Uploadcare`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`a (angle)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`a_90/`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`-/rotate/270/`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`c (crop/resize)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`c_crop,h_400,w_400/`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`-/crop/400x400/center/`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`q (quality)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`q_auto:best/`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`-/quality/best/`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`e (effect) grayscale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`e_grayscale/`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`-/grayscale/`}</code></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "filestack"
    }}><a parentName="h3" {...{
        "href": "#filestack"
      }}>{`Filestack`}</a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Operation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Filestack`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Uploadcare`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Resize`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`resize=width:600/`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`-/resize/600x/`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Crop`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`crop=dim:[0,0,400,400]/`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`-/crop/400x400/0,0/`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Flip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`flip/`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`-/flip/`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Flop`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`flop/`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "lang-text"
            }}>{`-/mirror/`}</code></td>
        </tr>
      </tbody>
    </table>
    <p>{`If you have questions or suggestions, be sure to contact our friendly Support engineers at `}<Link to="mailto:help@uploadcare.com" target="_blank" mdxType="Link"><a parentName="p" {...{
          "href": "mailto:help@uploadcare.com"
        }}>{`help@uploadcare.com`}</a></Link>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      