/* Vendors */
import React from 'react';
import styled from 'styled-components';
import { MDXProvider } from '@mdx-js/react';

/* Components */
import { Container } from '@uc-common/container';
import { SiteLayout } from '~/src/components/Layout/SiteLayout';
import { CallToAction } from '~/src/components/CallToAction/CallToAction';
import { PaddingBox } from '~/src/mdx/components/PaddingBox/PaddingBox';
import { Link } from '~/src/components/Link';
import { Paragraph } from '~/src/mdx/components/Paragraph/Paragraph';

/* Styles */
import { style } from './style';

const StyledContent = styled(Container)`
  ${style}
`;

/* Page */

export const AnchorAsLink = ({ href, ...props }) => <Link {...props} to={href} />;

const Img = ({ ...props }) => <img loading="lazy" alt="" {...props} />;

const MDXDefaultLayout = ({ location: { pathname }, frontmatter, children }) => {
  const meta = frontmatter ?? {};

  return (
    <SiteLayout meta={meta} pathName={pathname}>
      <MDXProvider
        components={{
          a: AnchorAsLink,
          PaddingBox,
          CallToAction,
          Link,
          Paragraph,
          img: Img,
        }}
      >
        <StyledContent as="article">
          {meta.title && <h1>{meta.header || meta.title}</h1>}
          {children}
        </StyledContent>
      </MDXProvider>
    </SiteLayout>
  );
};

export default MDXDefaultLayout;
