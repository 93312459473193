/* Vendors */
import React from 'react';
import styled from 'styled-components';

/* Components */
import { Link } from '../Link';

/* Utils */

/* Types */

/* Styles */
const Component = styled.div`
  text-align: center;
`;

const ActionDescription = styled.p`
  color: var(--color-grey);
  padding-bottom: 0;
  margin-top: 16px;
  font-size: 15px;
  line-height: 20px;
`;

/* Component */
export const CallToAction = React.forwardRef(
  ({ className, text, to, description, ...rest }, ref) => (
    <Component ref={ref} className={className}>
      <Link to={to} size="l" visual="rounded-primary" {...rest}>
        {text}
      </Link>
      {description && <ActionDescription>{description}</ActionDescription>}
    </Component>
  )
);

/* default props */
CallToAction.defaultProps = {};
